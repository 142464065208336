import styled from "styled-components";
import { palette } from "styled-theme";

export default styled.div`
  align-items: center;
  text-align: center;
  padding-top: 40px;
  flex: 1;
  img {
    height: 120px;
    width: 180px;
    cursor: pointer;
  }
  p {
    margin-top: 2px;
    font-size: 28px;
    font-weight: bold;
    color: ${palette("color", 15)};
    font-family: "Caveat", cursive;
  }
`;
