import React from "react";
import SidebarLogo from "./sidebarLogo";
import Footer from "../footer";
import SidebarMobile from "./sidebarMobile";
import Wrapper from "./sidebar.styles";
import SidebarContent from "./sidebarContent";

const Sidebar = ({ showSidebar, setShowSidebar, shouldShowSidebarContent, sidebarText }) => (
  <>
    <Wrapper>
      <SidebarLogo />
      <SidebarContent sidebarText={sidebarText} shouldShowSidebarContent={shouldShowSidebarContent} />
      <Footer />
    </Wrapper>
    <SidebarMobile
      showSidebar={showSidebar}
      setShowSidebar={setShowSidebar}
      shouldShowSidebarContent={shouldShowSidebarContent}
    />
  </>
);

export default Sidebar;
