import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import actions from "../redux/applicationForm/actions";

export default (fieldName, defaultValue = "", isLoading = false) => {
  const dispatch = useDispatch();
  const [hasSetDefault, setHasSetDefault] = useState(false);

  useEffect(() => {
    if (!hasSetDefault && !isLoading ) {
      dispatch(actions.setFieldInitialValue({[fieldName]: defaultValue}));
      setHasSetDefault(true);
    }
  }, [fieldName, defaultValue, hasSetDefault, setHasSetDefault, dispatch, isLoading]);
};