export const PUBLIC_ROUTE = {
  INDEX: "/",
  PAGE_NOT_FOUND: "",
  LOG_OUT: "/logout",
};

export const PRIVATE_ROUTE = {
  LANDING: "/dashboard",
  RESULTS: "/results",
  ERROR: "/error",
};

export const API_ROUTES = {
  SUBMIT: "/cee/api/Eligibility",
  LOAN_REASONS: "/cee/api/LoanReason/GetProducts",
}