import React from "react";
import Wrapper from "./sidebarContent.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const SidebarContent = ({ shouldShowSidebarContent, sidebarText }) => {
  
  return (
    <Wrapper>
      {shouldShowSidebarContent && (
          <div className="link-container">
            <span className="checkbox-container" >
              <FontAwesomeIcon icon={faCheck} color="#fff" size="1x" />
            </span>
            <p>{sidebarText}</p>
          </div>
      )}
    </Wrapper>
  );
};

export default SidebarContent;
