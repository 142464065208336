import styled from "styled-components";
import { palette, size } from "styled-theme";
import { isIE11 } from "../../utils/helpers/browserHelper";

const Wrapper = styled.div`
  .modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    z-index: 999999;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    padding-top: 100px;
    padding-bottom: 50px;

    @media only screen and (max-width: ${size("M")}) {
      padding-top: 20px;
      padding-bottom: 75px;
    }

    transition: background-color 0.3s ease;
    background-color: ${palette("modal", 2)};

    &.ReactModal__Overlay--after-open {
      background-color: rgba(0, 0, 0, 0.8);
    }

    &.is-closing {
      background-color: ${palette("modal", 2)};
    }
  }

  .modal-container {
    display: flex;
  }

  .modal-container, .modal-content {
    max-height: 100%;
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: ${palette("color", 16)};
    text-align: left;
    font-size: ${size("p5")};

    @media only screen and (max-width: ${size("M")}) {
      max-width: calc(100vw - 40px) !important;
    }

    ${isIE11 && `
      max-height: calc(100vh - 100px);
    `}
    &.extra-small {
      max-width: 358px;
      /* IE11 max-width px fix */
      flex: 1 0 358px;
    }

    &.small {
      max-width: 500px;
      /* IE11 max-width px fix */
      flex: 1 0 500px;
    }

    &.medium {
      max-width: 60vw;
      width: 100%;
    }

    &.large {
      max-width: 90vw;
      width: 100%;
    }

    p {
      margin-top: ${size("p5")};
      margin-bottom: ${size("p5")};
    }
  }

  .modal-Body {
    overflow: auto;
    padding: 8px 16px;
    color: ${palette("color", 17)};
  }

  .modal-Header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: left;
    background-image: linear-gradient(to right, ${palette("topMenu", 0)}, ${palette("topMenu", 1)}, ${palette("topMenu", 2)});
    color: ${palette("color", 16)};
    padding: 20px;
  }

  .modal-Title {
    display: block;
    width: 90%;
    text-align: center;
    font-size: ${size("subheading")};
    font-weight: 600;
    text-transform: uppercase;
  }

  .modal-empty-div {
    width: 5%;
  }

  .modal-Footer {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    background-color: ${palette("color", 16)};
    color: black;
    padding: 12px 16px 14px;
  }

  .modal-Close-Icon {
    align-self: center;
    background: none;
    border: none;
    color: ${palette("color", 16)};
    float: right;
    font-size: 24px;
    font-weight: bold;
    width: 5%;
  }

  .modal-Close-Icon:hover,
  .modal-Close-Icon:focus {
    color: ${palette("color", 21)};
    cursor: pointer;
  }
`;

export default Wrapper;
