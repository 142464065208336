/**
 * @description Common Saga combined
 */

import { all } from "redux-saga/effects";
import startUpSagas from "../redux/startup/sagas";
import eligibilitySagas from "../redux/eligibility/sagas";
import contentDeliverySagas from "../redux/contentDelivery/sagas";

export default function* rootSaga(getState) {
  yield all([
    startUpSagas(getState), 
    eligibilitySagas(getState),
    contentDeliverySagas(getState),
  ]);
}
