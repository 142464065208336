import styled from "styled-components";
import { palette, size } from "styled-theme";

export default styled.div`
  text-align: left;
  flex: 1;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  img {
    height: 40px;
    width: 40px;
  }

  p {
    font-size: 14px;
    color: ${palette("color", 16)};
  }

  .copyright-container {
    padding-left: 40px;
  }

  .separator {
    border-top: 1px solid ${palette("color", 16)};
    margin: 10px 30px;
  }

  .lock-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 40px;
  }

  .lock-container p {
    margin-left: 10px;
    margin-top: 5px;
  }

  .lock-container span:first-child {
    font-weight: bold;
  }

  .lock-container span:nth-child(2) {
    color: ${palette("color", 45)};
  }

  @media only screen and (max-width: ${size("XM")}) {
    padding-bottom: 0px;
    text-align: left;
    background-color: ${palette("color", 47)};

    p {
      color: ${palette("color", 16)};
    }

    .lock-container {
      padding: 20px;
      justify-content: flex-start;
    }

    .lock-container p {
      color: ${palette("color", 16)};
    }

    .copyright-container {
      margin: 20px 5% 0px 20px;
      padding: 0 5px 20px 0;
      border-bottom: 1px solid #64a181;

      p {
        color: ${palette("color", 16)};
      }
    }

    .separator {
      border-top: 0px;
      margin: 0px;
    }
  }
`;
