import React from "react";
import cx from "classnames";
import Svg from "./spinner.style";

export const Spinner = ({ type }) => (
  <Svg data-testid="loader" viewBox="0 0 50 50">
    <circle
      className={cx("isoContentLoaderCircle", type === "light" ? "whiteCircle" : "greenCircle")}
      cx="25"
      cy="25"
      r="20"
      fill="none"
      strokeWidth="3.6"
    />
  </Svg>
);