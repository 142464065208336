import actions from "./actions";
import { THEMES } from "../../theme/global/constants";

const initState = {
  queryString: {},
  loanReasonsIsFetching: false,
  loanReasonsResponse: null,
  loanReasonsError: null,
  theme: THEMES.loading
};

const mergeNewGetState = (stateName, state, isFetching, response, error) => ({
  ...state,
  [stateName + "IsFetching"]: isFetching,
  [stateName + "Response"]: response,
  [stateName + "Error"]: error
});

const stateResolverStartFor = (stateName, state) =>
  mergeNewGetState(stateName, state, true, null, null);

const stateResolverSuccess = (state, action) => {
  const { stateName, values } = action.payload;
  return mergeNewGetState(stateName, state, false, values, null);
}

const stateResolverError = (state, action) => {
  const { stateName, ...error } = action.payload;
  return mergeNewGetState(stateName, state, false, null, error);
}

export default function(state = initState, action) {
  switch (action.type) {
    case actions.GET_LOAN_REASONS_START:
      return stateResolverStartFor("loanReasons", state);
    case actions.GET_SUCCESS:
      return stateResolverSuccess(state, action);
    case actions.GET_ERROR:
      return stateResolverError(state, action);
    case actions.INITIALISE_QUERY_STRING:
      return {
        ...state,
        queryString: action.payload,
      };
    case actions.CHANGE_THEME:
      return {
        ...state,
        theme: action.payload
      };
    default:
      return state;
  }
}
