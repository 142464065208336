import styled from "styled-components";
import { palette, size } from "styled-theme";

export default styled.div`
  display: none;
  flex-direction: row;
  margin-right: 5%;
  align-items: center;

  button, svg {
    cursor: pointer;
  }

  .btn-container {
    background-color: transparent;
    border-radius: 30px;
    border: 1px solid ${palette("color", 40)};
    color: ${palette("color", 40)};
    padding: 10px 20px 10px 20px;
    text-align: left;
    font-weight: bold;
  }

  .btn-container:hover {
    background-color: ${palette("color", 35)};
    border: 1px solid ${palette("color", 35)};
    color: ${palette("color", 16)};
  }

  .btn-container svg: first-child {
    margin-right: 10px;
  }

  .btn-container svg: last-child {
    margin-left: 20px;
  }

  .mobile-container {
    padding: 10px 15px;
  }

  .mobile-container svg {
    color: ${palette("color", 47)};
    margin-right: 15px;
  }

  a {
    text-decoration: none;
    color: ${palette("color", 47)};
    font-size: 18px;
    font-weight: 600;
  }

  .bell-icon {
    padding: 10px 15px;
  }

  .profile-btn button {
    background-color: ${palette("color", 40)};
    height: 40px;
    width: 40px;
    border-radius: 40px;
    border: 2px solid ${palette("color", 40)};
    color: ${palette("color", 16)};
    font-size: ${size("paragraph")};
    font-weight: bold;
    margin: 0px 10px;
  }

  .profile-btn button: hover {
    background-color: ${palette("color", 35)};
    border: 2px solid ${palette("color", 35)};
  }

  @media only screen and (min-width: ${size("XM")}) {
    display: flex;
  }
`;
