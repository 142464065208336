// once we have unit tests/later we can swap the above to use this utility
import { IDENTIFICATION_TYPES_VALUES, LIVING_SITUATIONS, RESIDENTIAL_STATUS, STATES, FREQUENCY } from "./commonConstants";

const unsetValue = "Unset Option ValueDONOTUSE";
export const createOption = (label, value = unsetValue) => ({ label, value: value !== unsetValue ? value : label  });

export const LOAN_REASON_OPTIONS = Object.freeze([
  { label: "Bond Loan", value: "Bond Loan" },
  { label: "Household Bills", value: "Household Bills" },
  { label: "Travel Holiday", value: "Travel Holiday" },
  { label: "Vehicle Purchase", value: "Vehicle Purchase" },
  { label: "Consolidate Debt", value: "Consolidate Debt" },
  { label: "Home Renovations", value: "Home Renovations" },
  { label: "Furniture Appliances", value: "Furniture Appliances" },
  { label: "Funeral Expenses", value: "Funeral Expenses" },
  { label: "Wedding Expenses", value: "Wedding Expenses" },
  { label: "Medical/Dental Costs", value: "Medical/Dental Costs" },
  { label: "Training For Me", value: "Training For Me" },
  { label: "Education For Children", value: "Education For Children" },
  { label: "Car Repairs", value: "Car Repairs" },
]);

export const NAME_PREFIX = Object.freeze([
  { label: "Mr", value: "Mr" },
  { label: "Mrs", value: "Mrs" },
  { label: "Ms", value: "Ms" },
  { label: "Miss", value: "Miss" }
]);

export const PAYMENT_FREQUENCY_OPTIONS = Object.freeze([
  createOption(FREQUENCY.WEEKLY),
  createOption(FREQUENCY.FORTNIGHTLY),
  createOption(FREQUENCY.MONTHLY),
]);

export const DAYS = (() => {
  const Days = [];
  for (let i = 1; i < 32; i++) {
    const day = i < 10 ? `0${i}` : `${i}`;
    Days.push({ label: day, value: i });
  }
  return Object.freeze(Days);
})();

export const MONTHS = Object.freeze([
  { value: 1, label: "Jan" },
  { value: 2, label: "Feb" },
  { value: 3, label: "Mar" },
  { value: 4, label: "Apr" },
  { value: 5, label: "May" },
  { value: 6, label: "Jun" },
  { value: 7, label: "Jul" },
  { value: 8, label: "Aug" },
  { value: 9, label: "Sep" },
  { value: 10, label: "Oct" },
  { value: 11, label: "Nov" },
  { value: 12, label: "Dec" }
]);

export const DEPENDENTS_OPTIONS = [
  createOption("0"),
  createOption("1"),
  createOption("2"),
  createOption("3"),
  createOption("4"),
  createOption("5"),
  createOption("6+"),
]

export const YEARS = (min = 18, max = 81, upcommingYears = false) => {
  const years = [];
  const date = new Date();
  if (upcommingYears)
    for (let i = date.getUTCFullYear() - min; i <= date.getUTCFullYear() + max; i++) {
      years.push({ label: i, value: i });
    }
  else
    for (let i = date.getUTCFullYear() - min; i > date.getUTCFullYear() - max; i--) {
      years.push({ label: i, value: i });
    }
  return Object.freeze(years);
};

export const OCCUPATION_OPTIONS = Object.freeze([
  createOption("Agriculture, Forestry and Fishing"),
  createOption("Mining"),
  createOption("Manufacturing"),
  createOption("Electricity, Gas, Water and Waste Services"),
  createOption("Construction"),
  createOption("Wholesale Trade"),
  createOption("Retail Trade"),
  createOption("Accommodation and Food Services"),
  createOption("Transport, Postal and Warehousing"),
  createOption("Information Media and Telecommunications"),
  createOption("Financial and Insurance Services"),
  createOption("Rental, Hiring and Real Estate Services"),
  createOption("Professional, Scientific and Technical Services"),
  createOption("Administrative and Support Services"),
  createOption("Public Administration and Safety"),
  createOption("Education and Training"),
  createOption("Health Care and Social Assistance"),
  createOption("Arts and Recreation Services"),
  createOption("Other Services"),
]);

export const EMPLOYMENT_OPTIONS = Object.freeze([
  { label: "Full Time Work", value: "Full-Time Work" },
  { label: "Part Time Work", value: "Part-Time Work" },
  { label: "Casual Work", value: "Casual Work" },
  { label: "Self Employed", value: "Self Employed" },
  { label: "Job Seeker", value: "Job Seeker" },
  { label: "Centrelink", value: "Centrelink" },
  { label: "Other", value: "Other" }
]);

export const LIVING_SITUATION_OPTIONS = Object.freeze([
  createOption("I live alone"),
  createOption(LIVING_SITUATIONS.LIVE_WITH_MY_FRIEND),
  createOption(LIVING_SITUATIONS.LIVE_WITH_PARTNER),
  createOption("I live with my parents (or other relatives)", "I live with my parents"),
]);

export const COUNT_OPTIONS = (min = 0, max = 6) => {
  const COUNT = [];
  for (let i = min; i <= max; i++) {
    if (i !== max) COUNT.push({ label: `${i}`, value: `${i}` });
    else COUNT.push({ label: `${i}+`, value: `${i}+` });
  }
  return Object.freeze(COUNT);
};

export const RESIDENTIAL_STATUS_OPTIONS = Object.freeze([
  createOption(RESIDENTIAL_STATUS.RENTING),
  createOption(RESIDENTIAL_STATUS.MORTGAGE),
  createOption("Boarding (with friends/family/relatives)", RESIDENTIAL_STATUS.BOARDING),
  createOption("Homeowner (no mortgage owing)", RESIDENTIAL_STATUS.HOMEOWNER),
]);

export const RESIDENTIAL_PAYMENT_FREQUENCY = Object.freeze([
  createOption("Weekly"),
  createOption("Fortnightly"),
  createOption("Monthly")
]);

export const IDENTIFICATION_TYPE_OPTIONS = Object.freeze([
  createOption("Drivers Licence", IDENTIFICATION_TYPES_VALUES.DRIVERS_LICENCE),
  createOption("Medicare", IDENTIFICATION_TYPES_VALUES.MEDICARE),
]);

// todo: move all of the states here into STATES object
export const STATE_OPTIONS = Object.freeze([
  createOption(STATES.ACT),
  createOption(STATES.NSW),
  createOption(STATES.NT),
  createOption(STATES.QLD),
  createOption(STATES.SA),
  createOption(STATES.TAS),
  createOption(STATES.VIC),
  createOption(STATES.WA),
]);

export const CARD_COLOUR_OPTIONS = Object.freeze([
  { label: "Green", value: "Green" },
  { label: "Blue", value: "Blue" },
  { label: "Yellow", value: "Yellow" },
]);

export const RELATIONSHIP_TO_YOU_OPTIONS = Object.freeze([
  { label: "Family (Parent)", value: "Parent" },
  { label: "Family (Sibling)", value: "Sibling" },
  { label: "Friend", value: "Friend" },
  { label: "Relative", value: "Relative" },
  { label: "Colleague", value: "Colleague" },
  { label: "Partner", value: "Partner" },
]);

export const AMOUNT_BALANCE_OPTIONS = Object.freeze([
  createOption("Minimum"),
  createOption("Part"),
  createOption("Full Balance")
]);


