import actions from "./actions";
import { APP_STATE } from "../../constants/commonConstants";

const initState = {
  bannerData: {},
  bannerDataState: "",
};

export default function (state = initState, action) {
  const { type, payload } = action;
  switch (type) {
    case actions.GET_BANNER_CONTENT_DATA_START:
      return {
        ...state,
        bannerDataState: APP_STATE.LOADING,
      };
    case actions.GET_BANNER_CONTENT_DATA_FAILED:
      return {
        ...state,
        bannerDataState: APP_STATE.ERROR,
      };
    case actions.GET_BANNER_CONTENT_DATA_SUCCESS:
      return {
        ...state,
        bannerDataState: APP_STATE.READY,
        bannerData: payload,
      };
    default:
      return state;
  }
}
