import actions from "./actions";

export const prepareEligibilityForm = (values = {}) => {
  return { ...values };
};

const readSessionStorageOrReturnInitialIfNull = (key, shouldReturn) => {
  const sessionData = JSON.parse(sessionStorage.getItem(key));
  if (sessionData === null) {
    return shouldReturn;
  }
  return sessionData;
};

const initState = {
  isSubmitting: false,
  isErrorState: false,
  isNotFound: readSessionStorageOrReturnInitialIfNull('results_not_found', ""),
  eligibilityFormError: "",
  eligibilityFormSuccess: false,
  initialValues: readSessionStorageOrReturnInitialIfNull('form_values', prepareEligibilityForm()),
  customerEligibilityData: readSessionStorageOrReturnInitialIfNull('cee_results', {}),
};

export default function (state = initState, action) {
  const { type } = action;
  switch (type) {
    case actions.SUBMIT_CEE_FORM:
      return {
        ...state,
        initialValues: prepareEligibilityForm({
          ...state.initialValues,
          ...action.payload.values,
        }),
      };
    case actions.SUBMIT_FORM_START:
      return {
        ...state,
        isSubmitting: true,
        isNotFound: "",
      };
    case actions.SET_ERROR_STATE:
      return {
        ...state,
        isErrorState: true,
      };
    case actions.CLEAR_ERROR_STATE:
      return {
        ...state,
        isErrorState: false,
      };
    case actions.SET_NOT_FOUND:
      return {
        ...state,
        isSubmitting: false,
        isNotFound: action.payload,
      };
    case actions.SUBMIT_FORM_FAILED:
      return {
        ...state,
        isSubmitting: false,
        eligibilityFormError: action.payload,
      };
    case actions.SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        eligibilityFormSuccess: true,
        customerEligibilityData: action.payload,
      };
    case actions.CLEAR_SUCCESSFUL_SUBMISSION:
      return {
        ...state,
        eligibilityFormSuccess: false,
      };
    case actions.RESET_CEE_FORM:
      return {
        ...initState,
      };
    default:
      return state;
  }
}
