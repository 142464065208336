import React from "react";
import Wrapper from "./footer.styles";
import LockIcon from "../../theme/assets/icon-padlock.svg";
import { currentYear } from "../../utils/helpers/dateHelper";

const Footer = () => (
  <Wrapper>
    <div className="copyright-container">
      <p>
        © 2012-{currentYear} Fair Go Finance <br /> ACN 134 369 574 | ACL 387995 <br />
      </p>
    </div>
    <div className="separator" />
    <div className="lock-container">
      <img src={LockIcon} alt="lock-icon" />
      <p>
        <span>Secure &amp; Private</span> <span>&hearts;</span> <br />
        Your info is safe with us
      </p>
    </div>
  </Wrapper>
);

export default Footer;
