import styled from "styled-components";
import { size } from "styled-theme";

export const Paper = styled.div`
  width: 100%;
  box-sizing: border-box;

  @media only screen and (min-width: ${size("XM")}) {
    width: 80%;
    max-width: calc(100% - 300px);
    float: right;
    padding: 0;
  }
`;

export const PaddedPaper = styled(Paper)`
  padding: 0 20px;
`;