import { all, call, takeLatest, put } from "redux-saga/effects";
import actions from "./actions";
import { getTheme } from "../../theme/config/theme.config";
import { getLoanReasons } from "../../services/apiRoutes";

const createGetterSaga = (stateName, getter, mapper) => {
  return function*() {
    try {
      const response = yield call(getter);
      const { status, data } = response;
      const { value } = data;
      if (status === 200) {
        const values = (mapper || ((v) => v))(value || []);
        yield put(actions.getSuccess({stateName, values}));
      } else {
        yield put(actions.getError({ stateName, errMessage: "Something went wrong." }));
      }
    } catch (error) {
      yield put(
        actions.getError({
          stateName,
          errMessage: error.message || "Something went wrong."
        })
      );
    }
  }
}

function* setInitialTheme(action) {
  const { payload } = action;
  const theme = getTheme(payload);
  yield put(actions.changeTheme(theme));
}

export const __TEST__ = {
  setInitialTheme,
};

const loanReasons = createGetterSaga("loanReasons", getLoanReasons);

export default function* rootSaga() {
  yield all([
    takeLatest(actions.GET_LOAN_REASONS_START, loanReasons),
    takeLatest(actions.INITIALISE_QUERY_STRING, setInitialTheme)
  ]);
}
