import React from "react";
import { useOnMount } from "../../../hooks";
import { emptyFunction } from "../../../utils/helpers/emptyFunction";
import { googleApiKey } from "../../../constants/environmentSettings";

const fairGoInitMap = "fairGoInitMap";
const placesAutocompleteInit = "initMap";

const configureInitCallback = () => {
  window[fairGoInitMap] = () => (window[placesAutocompleteInit] || emptyFunction)();
};

function createScriptElement() {
  const mapScriptElement = document.createElement(`script`);
  mapScriptElement.src = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&libraries=places&callback=${fairGoInitMap}`;

  return mapScriptElement;
}

function appendScriptElement(mapScriptElement) {
  document
    .querySelector(`body`)
    .insertAdjacentElement(`beforeend`, mapScriptElement);
}

export const MapsApiScriptElement = () => {
  useOnMount(() => {
    configureInitCallback();
    appendScriptElement(createScriptElement());
  });
  
  return <></>;
};