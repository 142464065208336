import { all, call, put, takeLatest } from "redux-saga/effects";
import { prepareEligibilityForm } from "./reducer";
import actions from "./actions";
import { submitForm } from "../utility/shared";

function* submitEligibilityForm(action) {
  const { payload } = action;
  const { values, endpoint } = payload;

  yield put(actions.submitFormStart());

  const eligibilityFormPost = prepareEligibilityForm({ values });

  yield call(submitForm, endpoint, eligibilityFormPost.values, actions);
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.SUBMIT_CEE_FORM, submitEligibilityForm),
  ]);
}