import { createEmptyAction, createPayloadAction } from "../utility/actions";

const GET_BANNER_CONTENT_DATA_START = "GET_BANNER_CONTENT_DATA_START";
const GET_BANNER_CONTENT_DATA_FAILED = "GET_BANNER_CONTENT_DATA_FAILED";
const GET_BANNER_CONTENT_DATA_SUCCESS = "GET_BANNER_CONTENT_DATA_SUCCESS";

const actions = {
  GET_BANNER_CONTENT_DATA_START,
  GET_BANNER_CONTENT_DATA_FAILED,
  GET_BANNER_CONTENT_DATA_SUCCESS,
  getBannerContentData: createEmptyAction(GET_BANNER_CONTENT_DATA_START),
  getBannerContentDataFailed: createEmptyAction(GET_BANNER_CONTENT_DATA_FAILED),
  getBannerContentDataSuccess: createPayloadAction(GET_BANNER_CONTENT_DATA_SUCCESS),
};

export default actions;
