import { createEmptyAction, createPayloadAction } from "../utility/actions";

const SUBMIT_CEE_FORM = "SUBMIT_CEE_FORM";
const SUBMIT_FORM_START = "SUBMIT_FORM_START";
const SUBMIT_FORM_FAILED = "SUBMIT_FORM_FAILED";
const SUBMIT_FORM_SUCCESS = "SUBMIT_FORM_SUCCESS";
const RESET_CEE_FORM = "RESET_CEE_FORM";
const SET_ERROR_STATE = "SET_ERROR_STATE";
const SET_NOT_FOUND = "SET_NOT_FOUND";
const CLEAR_ERROR_STATE = "CLEAR_ERROR_STATE";
const CLEAR_SUCCESSFUL_SUBMISSION = "CLEAR_SUCCESSFUL_SUBMISSION";

const actions = {
    SUBMIT_CEE_FORM,
    SUBMIT_FORM_FAILED,
    SUBMIT_FORM_SUCCESS,
    RESET_CEE_FORM,
    SUBMIT_FORM_START,
    SET_ERROR_STATE,
    SET_NOT_FOUND,
    CLEAR_ERROR_STATE,
    CLEAR_SUCCESSFUL_SUBMISSION,
    submitCeeForm: createPayloadAction(SUBMIT_CEE_FORM),
    submitFormStart: createEmptyAction(SUBMIT_FORM_START),
    submitFormFailed: createPayloadAction(SUBMIT_FORM_FAILED),
    submitFormSuccess: createPayloadAction(SUBMIT_FORM_SUCCESS),
    resetCeeForm: createEmptyAction(RESET_CEE_FORM),
    setErrorState: createEmptyAction(SET_ERROR_STATE),
    setNotFound: createPayloadAction(SET_NOT_FOUND),
    clearErrorState: createEmptyAction(CLEAR_ERROR_STATE),
    clearSuccessfulSubmission: createEmptyAction(CLEAR_SUCCESSFUL_SUBMISSION),
}

export default actions;