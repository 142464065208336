import defaultTheme from "./default";
import { THEMES } from "../global/constants";
import { toLowerObjectKeyValues } from "../../utils/helpers/objectHelper";

const themes = {};
themes[THEMES.default] = defaultTheme;
themes[THEMES.loading] = {};

export default themes;

export const getTheme = (queryString = {}) => {
  const toLowerQueryString = toLowerObjectKeyValues(queryString);
  
  const { utm_campaign, theme } = toLowerQueryString;

  const themeMap = {
    "rent": THEMES.rentBond,
    "clearscore": THEMES.clearScore
  };
  
  return themeMap[utm_campaign] || themeMap[theme] || THEMES.default;
};
