import React from "react";
import FairGoLogo from "../../theme/assets/fairgo-logo.svg";
import Wrapper from "./sidebarLogo.styles";
import { useHistory } from "react-router-dom";
import { PRIVATE_ROUTE } from "../../route.constants";
import { useCurrentQueryParameters } from "../../containers/Results/helpers/useCurrentQueryParameters";

const SidebarLogo = () => {
  const reconstructedQueryString = useCurrentQueryParameters() || "";
  const history = useHistory();

  const handleReturnToDashboard = () => {
    history.push({
      pathname: PRIVATE_ROUTE.LANDING + reconstructedQueryString,
    })
  }

  return (
    <Wrapper>
      <img onClick={handleReturnToDashboard} src={FairGoLogo} alt="Fair Go Finance" />
    </Wrapper>
  );
};

export default SidebarLogo;
