import React, { useState } from "react";
import Wrapper from "./pageWrapper.styles";
import MobileWrapper from "./pageWrapperMobile.styles";
import Sidebar from "../../components/sidebar";
import MainHeader from "../../components/mainHeader";
import MobileFooter from "../../components/footer";

export const PageWrapper = ({
  children,
  shouldShowSidebarContent,
  shouldShowHeader,
  sidebarText,
  firstName,
}) => {
  const [showSidebar, setShowSidebar] = useState(false);
  
  return (
    <>
      <Wrapper>
        <Sidebar
          showSidebar={showSidebar}
          sidebarText={sidebarText}
          setShowSidebar={setShowSidebar}
          shouldShowSidebarContent={
            shouldShowSidebarContent !== undefined
              ? shouldShowSidebarContent
              : true
          }
        />
        <MainHeader
          showSidebar={showSidebar}
          setShowSidebar={setShowSidebar}
          firstName={firstName}
          shouldShowHeader={
            shouldShowHeader !== undefined ? shouldShowHeader : true
          }
        />
        {children}
        <div className="push"></div>
      </Wrapper>
      <MobileWrapper>
        <MobileFooter />
      </MobileWrapper>
    </>
  );
};
