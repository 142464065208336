import { createPayloadAction, createEmptyAction } from "../utility/actions";
import { parseQueryString as queryStringParser } from "../../services/parseQueryString";

const START_UP = "START_UP";
const GET_SUCCESS = "GET_SUCCESS";
const GET_ERROR = "GET_ERROR";
const GET_LOAN_REASONS_START = "GET_LOAN_REASONS_START";
const INITIALISE_QUERY_STRING = "INITIALISE_QUERY_STRING";
const CHANGE_THEME="CHANGE_THEME";
const parseQueryString = () => {
  return {
    type: INITIALISE_QUERY_STRING,
    payload: queryStringParser(window.location.search || "")
  };
};

const actions = {
  START_UP,
  GET_SUCCESS,
  GET_ERROR,
  GET_LOAN_REASONS_START,
  INITIALISE_QUERY_STRING,
  CHANGE_THEME,
  startUp: createPayloadAction(START_UP),
  getLoanReasonsRequest: createEmptyAction(GET_LOAN_REASONS_START),
  getSuccess: createPayloadAction(GET_SUCCESS),
  getError: createPayloadAction(GET_ERROR),
  parseQueryString: parseQueryString,
  changeTheme: createPayloadAction(CHANGE_THEME),
};

export default actions;