import { toLowerCase } from "./stringHelper";

const returnerFunc = arg => arg;

const mapKeyValues = ({keyMapper = returnerFunc, valueMapper = returnerFunc}) => objectToMap =>
  Object
    .entries(objectToMap)
    .reduce(
      (accumulator, [key, value]) => {
        accumulator[keyMapper(key)] = valueMapper(value);
        return accumulator;
      }, {}
    );

export const toLowerObjectKeyValues = mapKeyValues({keyMapper: toLowerCase, valueMapper: toLowerCase});

export const toLowerObjectKeys = mapKeyValues({keyMapper: toLowerCase});