import styled from "styled-components";
import { palette, size } from "styled-theme";

export default styled.div`  

  &.show {
    .background {
      opacity: 0.6;
      z-index: 100;
    }
    
    .sidebar{
      display: block;
      right: 0;
    }
  }

  .background {
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: none;
    opacity: 0;
    transition: opacity 0.25s linear;
     
  }

  .sidebar{
    height: 100vh;
    background: ${palette("color", 35)};
    width: calc(100% - 60px);
    position: fixed;
    z-index: 100;
    right: -100%;
    transition: right 0.25s linear;

    svg {
      cursor: pointer;
    }

    .sidebar-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 60px;
      background: linear-gradient(to right, ${palette("topMenu", 0)}, ${palette("topMenu", 1)});
    }

    .left-container {
      margin-left: 40px;
    }

    .left-container span {
      font-size: ${size("paragraph")};
      font-weight: bold;
      margin-right: 10px;
    }

    .left-container svg:first-child {
      margin: 0px 10px 0px 20px;
    }

    .right-container a svg,
    .right-container button svg {
      margin-right: 20px;
    }

    .right-container button {
      background: transparent;
      border: 0px;
    }

    .more-options {
      background-color: ${palette("color", 42)};
    }

    .more-options svg {
      margin-left: 40px;
      margin-top: 15px;
    }

    .more-options div:last-child {
      padding-bottom: 15px;
    }

    .more-options span {
      margin-left: 10px;
      color: ${palette("color", 16)};
    }
  }
  @media only screen and (min-width: ${size("XM")}) {
    display: none;
    
    .background, 
    .sidebar {
      display: none;
    }
    
    &.show {
      .background {
        display: none;
      }
      
      .sidebar{
        display: none;
      }
    }
  }
`;
