import React from "react";
import styled from "styled-components";
import { size } from "styled-theme";
import PageWrapper from "../PageWrapper";
import { Paper } from "../../components/paper/paper";
import { Link } from "react-router-dom";
import { PRIVATE_ROUTE } from "../../route.constants";
import { useCurrentQueryParameters } from "../Results/helpers/useCurrentQueryParameters";

const FourOhFourPaper = styled(Paper)`
  margin-top: 50px;

  .four-oh-four-header {
    font-size: ${size("heading")};
    text-align: center;
    font-weight: bold;
  }

  .four-oh-four-description {
    max-width: 600px;
    padding: 40px 20px 0;
    text-align: center;
    margin: 0 auto;
  }
`;

export const FourOhFour = () => {
  const reconstructedQueryString = useCurrentQueryParameters() || "";

  return (
    <PageWrapper
      shouldShowSidebarContent={false}
      shouldShowHeader={false}
      shouldShowMobileFooter={false}
    >
      <FourOhFourPaper>
        <h1 className="four-oh-four-header">
          404
        </h1>

        <p className="four-oh-four-description">
          This page does not exist, click <Link to={PRIVATE_ROUTE.LANDING + reconstructedQueryString}>here</Link> to head back to the Eligibility Form.
        </p>
      </FourOhFourPaper>
    </PageWrapper>
  );
};
