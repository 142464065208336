import React from "react";
import Wrapper from "./mobileHeader.styles";
import FairGoLogo from "../../theme/assets/fairgo-logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import { UnStyledLink } from "../textButton";
import { PRIVATE_ROUTE } from "../../route.constants";
import theme from "../../theme/config/default";

const MobileHeader = () => {
  return (
    <Wrapper>
      <UnStyledLink to={PRIVATE_ROUTE.LANDING} className="left-container">
        <img src={FairGoLogo} alt="Fair Go Finance" />
      </UnStyledLink>
      <div className="right-container">
        <a href={`tel:1300 324 746`}>
          <FontAwesomeIcon icon={faMobileAlt} color={theme.palette.color[45]} size="lg" />
        </a>
      </div>
    </Wrapper>
  );
};

export default MobileHeader;
