import { combineReducers } from "redux";
import StartUp from "./startup/reducer";
import EligibilityData  from "./eligibility/reducer";
import ContentDelivery from "./contentDelivery/reducer";

export const createRootReducer = () => combineReducers({
  StartUp,
  EligibilityData,
  ContentDelivery,
});
