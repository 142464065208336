import { format } from "date-fns";

export function stringIsNotNullOrEmpty(strValue) {
  return !!strValue && strValue.trim() !== "";
}

const getLastCharactersOfString = (str, count) => {
  const amountString = str.toString();
  return amountString.slice(amountString.length - count);
};

const sliceMoneySegment = (amount) => {
  const thousandthOfAmount = Math.floor(amount / 1000);
  if (thousandthOfAmount > 0) {
    const lastThreeDigits = getLastCharactersOfString(amount, 3);

    return sliceMoneySegment(thousandthOfAmount) + "," + lastThreeDigits;
  }
  return amount;
};

export const getCents = (amount, removeZeroCents = true) => {
  const formattedZeroCents = removeZeroCents ? "" : ".00";

  if (amount === undefined || isNaN(amount) || amount === null) {
    return "" || formattedZeroCents;
  }

  const amountString = amount.toString();
  const regex = /(\.\d{1,2})/g;
  let cents = amountString.match(regex);
  if (!cents) {
    cents = [formattedZeroCents];
  }
  if (((cents.toString()).split("")).length === 2) {
    cents = (cents.toString()) + "0";
  }

  return cents.toString();
};

const createPrefix = (amount) => {
  return amount < 0 ? "- $" : "$";
};

const defaultOptions = { removeZeroCents: true }

export const formatMoney = (amount, options = {}) => {
  const optionsWithDefaults = { ...defaultOptions, ...options }
  const absoluteAmount = Math.abs(amount);
  const dollarAmount = Math.floor(absoluteAmount);
  return (
    createPrefix(amount) +
    sliceMoneySegment(dollarAmount) +
    getCents(absoluteAmount, optionsWithDefaults.removeZeroCents)
  );
};

export const formatNumberWhole = (amount) => {
  const absoluteAmount = Math.abs(amount);

  if (absoluteAmount === 0) {
    return "";
  }

  const dollarAmount = Math.floor(absoluteAmount);
  return (
    sliceMoneySegment(dollarAmount)
  );
}

export const formatPercent = (value) => {
  return Math.floor(value * 10000) / 100;
};

export function isNotNullOrEmpty(value) {
  return value !== undefined && value !== null && value !== "";
}

export function isNullOrEmpty(value) {
  return !isNotNullOrEmpty(value);
}

export function isNotNullOrUndefined(value) {
  return value !== undefined && value !== null;
}

export function formatDate(value, dateFormat) {
  return dateFormat !== undefined
    ? format(value, dateFormat)
    : format(value, "dd/MM/yyyy");
}

export function formatDateObject(date) {
  return formatDate(new Date(date.year, date.month - 1, date.day));
}

export function getMonthAndYear(value) {
  return value !== undefined
    ? `${value.toLocaleString("default", {
      month: "short",
    })} ${value.getUTCFullYear()}`
    : "";
}

export function getMonthsBetweenTwoDates(date, currentDate) {
  let months;
  months = (date.getUTCFullYear() - currentDate.getUTCFullYear()) * 12;
  months -= currentDate.getUTCMonth();
  months += date.getUTCMonth();
  return months <= 0 ? 0 : months;
}

export const toLowerCase = str => typeof str === "string" ? str.toLowerCase() : str;

export const removeDoubleSlashes = (url) => url.replace(/\/\//, "/");