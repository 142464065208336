import React from "react";
import Wrapper from "./headerOptions.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";

const HeaderOptions = () => {

  return (
      <Wrapper>
        <div className="mobile-container">
          <FontAwesomeIcon icon={faMobileAlt} size="lg" />
          <a href={`tel:1300 324 746`}>1300 324 746</a>
        </div>
      </Wrapper>
  );
};

export default HeaderOptions;
