import styled from "styled-components";
import { size } from "styled-theme";

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .left-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
  }

  .left-container img {
    height: 60px;
    width: 90px;
  }

  .right-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 15px;
  }

  .right-container button,
  a {
    background: transparent;
    border: 0px;
    cursor: pointer;
  }

  .right-container button svg,
  a svg {
    padding-left: 10px;
    z-index: 2;
  }

  @media only screen and (min-width: ${size("XM")}) {
    display: none;
  }
`;
