import React from "react";
import styled from "styled-components";
import { palette, font } from "styled-theme";
import { Link } from "react-router-dom";

export const UnStyledButton = styled.button.attrs(({type = "button"}) => ({
  type // default TextButton to be button instead of submit
}))`
  text-align: left;
  font-family: ${font("primary")};
  text-decoration: none;
  font-family: ${font("primary")};
  background: none;
  margin: 0;
  padding: 0;
  border: none;
  -moz-user-select: text;
  cursor: pointer;
  outline: none;
  ${props => props.removeOutline ? "outline: none" : ""}
  
  &:disabled {
    cursor: default;
  }
`;

const UnStyledLinkA = styled.a`
  text-decoration: none;
`;

const UnStyledRouterLink = styled(Link)`
  text-decoration: none;
`;

const UnStyledLinkButton = styled.button`
  text-decoration: none;
  padding: 0;
`;

export const UnStyledLink = ({to, onClick, href, ...otherProps}) => {
  if (to) {
    return <UnStyledRouterLink to={to} {...otherProps} />;
  }

  if (onClick) {
    return <UnStyledLinkButton onClick={onClick} {...otherProps} />;
  }

  return <UnStyledLinkA href={href} {...otherProps} />;
}

export const TextButton = styled(UnStyledButton)`
  color:${palette("color", 26)};
  text-decoration: underline;
  font-size: 13px;
`;

export const TextButtonWithChevron = styled(UnStyledButton)`
  color:${palette("color", 21)};
  font-size: 14px;
`;