import React from "react";
import Wrapper from "./sidebarMobile.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMobileAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";

const SidebarMobile = ({
  showSidebar,
  setShowSidebar,
}) => {

  return (

      <Wrapper className={ showSidebar ? "show" : ""}>
        <div className="background" onClick={() => setShowSidebar(!showSidebar)}/>
        <div className="sidebar">
          <div className="sidebar-header">
            <div className="left-container">
            </div>
            <div className="right-container">
              <a href={`tel:1300 324 746`}>
                <FontAwesomeIcon icon={faMobileAlt} color="#9ae82e" size="lg" />
              </a>
              <button onClick={() => setShowSidebar(!showSidebar)}>
                <FontAwesomeIcon icon={faTimes} color="#fff" size="lg" />
              </button>
            </div>
          </div>
        </div>
      </Wrapper>
    )
};

export default SidebarMobile;
