import styled from "styled-components";
import { size } from "styled-theme";

const MobileWrapper = styled.div`
  height: 151px;
  
  @media only screen and (min-width: ${size("XM")}) {
      display: none;
  }
`;

export default MobileWrapper;
