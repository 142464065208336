import styled from "styled-components";
import { palette, size } from "styled-theme";

export default styled.div`
  margin: 20px 40px;

  .link-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    margin-bottom: 15px;

    p {
      color: ${palette("color", 41)};
      font-weight: 600;
      margin-left: 10px;
      transition: 0.15s color linear;
      word-break: normal;
      font-size: 17px;
    }
    .checkbox-container {
      height: 30px;
      width: 30px;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: 0.15s border linear, 0.15s background-color linear;

      background-color: ${palette("color", 41)};
      border: 2px solid ${palette("color", 41)};

      svg {
        transition: 0.15s all linear;
        background: ${palette("color", 41)};
        color: ${palette("color", 35)};
      }
    }
  }

  @media only screen and (min-width: ${size("XM")}) {
    margin: 20% 0;
    flex: 3;

    .link {
      text-decoration: none;
    }
    .link-container {
      margin-left: 16%;
      padding-right: 1%;
      margin-bottom: 6px;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 4%;
      border-radius: 25px 0 0 25px;
      background-color: white;

      p {
        color: ${palette("color", 47)};
      }

      .checkbox-container {
        background-color: ${palette("color", 45)};
        border: 2px solid ${palette("color", 45)};

        svg {
          color: ${palette("color", 16)};
          background: transparent;
        }
      }
    }

    button {
      width: 60%;
      margin: 8px 20% 0;
    }
  }
`;
