import styled from "styled-components";
import { palette, size } from "styled-theme";

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props => props.firstName? "space-between" : "flex-end"};
  align-items: center;  
  width: 100%;
  height: 60px;
  background-color: ${palette("color", 47)};
  color: ${palette("color", 47)};

  .customer-name-span {
      color: ${palette("color", 45)};
      margin-left: 10px;
      font-size: ${size("subheading")};
      display: none;
  }

  .customer-name {
    color: ${palette("color", 47)};
    margin-left: 60px;
    justify-self: start;
    font-size: ${size("subheading")};
    display: none;
  }

  @media only screen and (min-width: ${size("XM")}) {
    height: 80px;
    width: 80%;
    max-width: calc(100% - 300px);
    background: #EFEFEF;
    float: right;

    .customer-name, .customer-name-span {
      display: inline;
    }
  }
`;
