import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
import { PRIVATE_ROUTE, PUBLIC_ROUTE } from "./route.constants";
import Loader, { LoadingStubber } from "./components/loader";
import { Redirect } from "./components/fairGoRedirect/fairGoRedirect";
import { PrivateRoute } from "./components/routes";
import FourOhFour from "./containers/FourOhFour";

const privateRoutes = [
  {
    path: PRIVATE_ROUTE.LANDING,
    exact: false,
    component: lazy(() => import("./containers/Dashboard")),
  },
  {
    path: PRIVATE_ROUTE.RESULTS,
    exact: false,
    component: lazy(() => import("./containers/Results/resultPageContainer")),
  },
  {
    path: PRIVATE_ROUTE.ERROR,
    exact: false,
    component: lazy(() => import("./containers/Results/ErrorPage/errorPageContainer")),
  },
];

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <BrowserRouter>
          <Switch>
            <Route path={PUBLIC_ROUTE.INDEX} exact={true}>
              <Redirect pathname={PRIVATE_ROUTE.LANDING} withSearch />
            </Route>
            {privateRoutes.map((route, index) => (
              <PrivateRoute key={index} path={route.path} exact={route.exact}>
                <LoadingStubber>
                  <route.component />
                </LoadingStubber>
              </PrivateRoute>
            ))}
            <Route path="*" component={FourOhFour} />
          </Switch>
        </BrowserRouter>
      </Suspense>
    </ErrorBoundary>
  );
}
