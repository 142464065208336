import { call, put } from "redux-saga/effects";
import authenticatedEligibilityApi from "../../utils/eligibilityApi";
import { ELIGIBILITY_SCORE_BANDS } from "../../constants/commonConstants";

export const postEligibilityForm = async (endpoint, loanForm) =>
  authenticatedEligibilityApi.post(endpoint, loanForm);

export function* submitForm(endpoint, loanFormValues, actions) {
  sessionStorage.setItem("form_values", JSON.stringify(loanFormValues));
  try {
    const response = yield call(postEligibilityForm, endpoint, loanFormValues);
    const { status } = response;

    switch (status) {
      case 200:
        yield put(actions.submitFormSuccess(response.data));
        sessionStorage.setItem("cee_results", JSON.stringify(response.data));
        const { errors } = response.data;
        if (errors.error !== undefined) {
          if (errors.error[0] === "Credit file can't be extracted") {
            yield put(actions.setNotFound(true));
            sessionStorage.setItem("results_not_found", true);
          }
        } else {
          const toggleShowResultsNotFound = response.data.value.eligibilityScoreBand === ELIGIBILITY_SCORE_BANDS.NO_DETERMINATION;
          yield put(actions.setNotFound(toggleShowResultsNotFound));
          sessionStorage.setItem("results_not_found", toggleShowResultsNotFound);
        }
        break;
      default: {
        yield put(actions.setErrorState());
        yield put(actions.setNotFound(false));
        sessionStorage.setItem("results_not_found", false);
        yield put(
          actions.submitFormFailed(
            "An error has occured, please restart your submission"
          )
        );
        console.log(response.errors.error);
      }
    }
  } catch (error) {
    yield put(actions.setErrorState());
    yield put(actions.setNotFound(false));
    sessionStorage.setItem("results_not_found", false);
    yield put(
      actions.submitFormFailed(
        "An error has occured, please restart your submission"
      )
    );
    const errorData = error.response.data.errors;
    console.log("The following error(s) have occured:");
    for (var key in errorData) {
      if (errorData.hasOwnProperty(key)) {
        console.log(errorData[key][0]);
      }
    }
  }
}
