import { isUndefined } from "lodash";
import { useSelector } from "react-redux";

export const useCurrentQueryParameters = () => {
  const {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_id,
    utm_term,
    utm_content,
  } = useSelector((state) => state.StartUp.queryString);

  let reconstructedQueryString = "";
  let utmArray = ["/?"];

  if (!isUndefined(utm_source)) {
    utmArray.push(`&utm_source=${utm_source}`);
  }

  if (!isUndefined(utm_medium)) {
    utmArray.push(`&utm_medium=${utm_medium}`);
  }

  if (!isUndefined(utm_campaign)) {
    utmArray.push(`&utm_campaign=${utm_campaign}`);
  }

  if (!isUndefined(utm_id)) {
    utmArray.push(`&utm_id=${utm_id}`);
  }

  if (!isUndefined(utm_term)) {
    utmArray.push(`&utm_term=${utm_term}`);
  }

  if (!isUndefined(utm_content)) {
    utmArray.push(`&utm_content=${utm_content}`);
  }

  if (utmArray.length === 1) {
    return;
  } else {
    reconstructedQueryString = utmArray.join("");
  }

  return reconstructedQueryString;
};
