export const APP_STATE = {
  LOADING: "loading",
  READY: "ready",
  ERROR: "error",
};

export const THIS_APP = "FGF CEE Form";

export const PERSONAL_LOAN_REASONS = [
  "Bond Loan",
  "Travel Holiday",
  "Debt Consolidation",
  "Furniture Appliances",
  "Home Renovations",
  "Funeral Expenses",
  "Wedding Expenses",
  "Medical / Dental",
  "Vet Costs",
  "Cosmetic Costs",
  "Training For Me",
  "Education & Training",
  "Car Repairs",
];

export const TRUE_FALSE_VALUES = {
  TRUE: "true",
  FALSE: "false",
};

export const PRODUCT_TYPE = {
  PERSONAL_LOAN: "personalLoan",
  CAR_LOAN: "carLoan",
  LINE_OF_CREDIT: "lineOfCredit",
  LINE_OF_CREDIT_DRAW_DOWN: "lineOfCreditDrawDown",
};

export const THEMES = {
  default: "defaultTheme",
  rentBont: "rentBontTheme",
};

export const STATES = {
  ACT: "ACT",
  NSW: "NSW",
  NT: "NT",
  QLD: "QLD",
  SA: "SA",
  TAS: "TAS",
  VIC: "VIC",
  WA: "WA",
};

export const IDENTIFICATION_TYPES_VALUES = {
  DRIVERS_LICENCE: "DriversLicence",
  MEDICARE: "MedicareCard",
};

export const LOAN_STATUS = {
  DECLINED: "Declined",
  CREATED: "Created",
  DECLINED_IN_PROGRESS: "DeclinedInProgress",
  NOT_RETRIEVABLE: "NotRetrievable",
  NOT_FOUND: "NotFound",
  INVALID: "Invalid",
};

export const LIVING_SITUATIONS = {
  LIVE_WITH_PARTNER: "I live with my partner",
  LIVE_WITH_MY_FRIEND: "I live with friend(s)",
};

export const RESIDENTIAL_STATUS = {
  RENTING: "Renting",
  MORTGAGE: "Mortgage",
  BOARDING: "Boarding",
  HOMEOWNER: "Homeowner",
};

export const FIELD_NAMES = {
  BANK_STATEMENTS_ID: "BankStatementsId",
  LOAN_AMOUNT: "loanAmount",
  LOAN_AMOUNT_ADDITIONAL: "__clientsideonly__loanAmountAdditional", // this is used purely in the form to determine to show SACC warning as loanAmount incs prev loan
  REPAYMENT_FREQUENCY: "repaymentFrequency",
  RESIDENTIAL_STATUS: "residentialStatus",
  LOAN_TYPE: "loanType",
  MINIMUM_REPAYMENT: "minimumRepayment",
  ACCOUNT_PURPOSE: "accountPurpose",
  NEW_LOAN_AMOUNT: "newLoanAmount",
  REDRAW_AMOUNT: "__clientsideonly__redrawAmount",
  PREVIOUS_ACCOUNT_TYPE: "__clientsideonly__previousAccountType",
  ACCOUNT_ID: "accountId",
  DRAW_DOWN_AMOUNT: "drawdownAmount",
  REPAYMENT_AMOUNT: "repaymentAmount",
  PROMISE_VALUE: "promiseValue",
  PROMISE_FIRST_DATE: "promiseFirstDate",
  START_DATE: "startDate",
  PAYMENT_VALUE: "paymentValue",
  CONTRACTUAL_PAYMENT_REGULAR: "__clientsideonly__contractualPaymentRegular",
  NETBALANCE_VALUE: "__clientsideonly__netBalance",
  MINIMUM_PAYMENT_VALUE: "__clientsideonly__minimumPaymentValue",
  ACCOUNT_TYPE: "__clientsideonly__accountType",
  LOAN_REASONS: "loanReason",
  TITLE: "title",
  FIRST_NAME: "firstName",
  LAST_NAME: "lastName",
  EMAIL: "emailAddress",
  MOBILE_NUMBER: "mobilePhone",
  DATE_OF_BIRTH: "dateOfBirth",
  SOURCE: "source",
  PRICE_RANGE_MIN: "__clientsideonly__min",
  PRICE_RANGE_MAX: "__clientsideonly__max",
  PRODUCT_TYPE_CLIENT_SIDE: "__clientsideonly_product",
  ADDRESS: "address",
  UNIT_NUMBER: "unitNumber",
  STREET_NUMBER: "streetNumber",
  STREET: "street",
  SUBURB: "suburb",
  POST_CODE: "postCode",
  STATE: "state",
  MARKETING_CONSENT: "marketingConsent",
  EMPLOYMENT_STATUS: "employmentStatus",
};

export const FORM_ID_PORTAL_PL_APPLY = "portal-form-pl-apply";
export const FORM_ID_PORTAL_LOC_APPLY = "portal-form-loc-apply";
export const FORM_ID_PORTAL_CAR_LOAN_APPLY = "portal-form-cl-apply";
export const FORM_ID_PORTAL_REFINANCE = "portal-form-refinance";
export const FORM_ID_PORTAL_LOC_DRAWDOWN = "portal-form-loc-drawdown";

export const isPositionStickySupported = (() => {
  const falseFunc = () => false;
  return "CSS" in window && (CSS.supports || falseFunc)("position", "sticky");
})();

export const REDIRECT_DELAY_SECONDS = {
  STANDARD: 1,
};

export const FREQUENCY = {
  WEEKLY: "Weekly",
  FORTNIGHTLY: "Fortnightly",
  MONTHLY: "Monthly",
};

export const PUBLIC_WEBSITE_PAGE = {
  MANAGE_REPAYMENTS: "/how-to-get-a-loan/repayments/",
  DECREASE_REPAYMENTS: "/how-to-get-a-loan/decrease-my-repayments",
};

export const INCREASE_PAYMENT_STATES = {
  PAYMENT_ENDING: "payment-ending",
  SCHEDULE_LESS_THAN_TWO: "scheduled-less-than-two",
  SCHEDULE_MORE_THAN_TWO: "scheduled-more-than-two",
  NO_PAYMENT: "no-payment",
};

export const MANAGE_MY_ARREARS_STATES = {
  ARREARS: "arrears",
  ARREARS_LESS_THAN_THREE: "arrears-less-than-three",
  ARREARS_EXPIRED_MATURITY_DATE: "arrears-expired-maturity-date",
  SHOW_CONTACT_US: "show-contact-us",
  REDIRECT_MANAGE_REPAYMENTS: "redirect-manage-repayments",
  ARREARS_LOC: "arrears-loc",
};

export const DECREASE_PAYMENT_STATES = {
  REDIRECT_MANAGE_REPAYMENTS: "redirect-manage-repayments",
  SHOW_CONTACT_US: "show-contact-us",
};

export const LOAN_RANGE = {
  SACC: {
    MAX: 2000
  },
  PERSONAL_LOAN: {
    MIN: 500,
    MAX: 10000,
  },
  LINE_OF_CREDIT: {
    MIN: 500,
    MAX: 3000,
  },
  CAR_LOAN: {
    MIN: 5001,
    MAX: 24000,
  },
};
export const MIN_REDRAW_AMOUNT = 200;


export const OPEN_ACCOUNT_NOTES = {
  TRANSACTION_SYMBOL: "#",
  TRANSACTION_NOTE:
    "This does not reflect any pending payments being processed.",
  LOC_REPAYMENT_SYMBOL: "^",
  LOC_REPAYMENT_NOTE:
    "If you've organised a higher payment this will not be shown.",
  BALANCE_SYMBOL: "*",
  BALANCE_NOTE:
    "Your outstanding balance may not include all accrued interest or fees.",
};

export const DECLINE_QUERY_STRING = "?utm_source=eligibility_estimator&utm_medium=results_page&utm_campaign=leadmarket";

export const ELIGIBILITY_SCORE_BANDS = {
  BELOW_AVERAGE: "Below Average",
  SHORTENED_BELOW_AVERAGE: "B. Average",
  AVERAGE: "Average",
  GOOD: "Good",
  GREAT: "Great",
  EXCELLENT: "Excellent",
  NO_DETERMINATION: "No Determination"
}