import React from "react";
import HeaderOptions from "./headerOptions";
import MobileHeader from "./mobileHeader";
import Wrapper from "./mainHeader.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import Bold from "../bold/bold";

const MainHeader = ({ shouldShowHeader, firstName }) =>
  shouldShowHeader && (
    <Wrapper firstName={firstName}>
      {firstName && <div className="customer-name">
          <FontAwesomeIcon icon={faUserCircle} color="#000" size="lg" /><Bold><span className="customer-name-span">Hi{" "}</span>{firstName}</Bold>
      </div>}
      <HeaderOptions firstName={firstName}/>
      <MobileHeader />
    </Wrapper>
  );

export default MainHeader;
