import styled from "styled-components";

const Wrapper = styled.div`
  min-height: 100%;
  margin-bottom: -151px;

  .push {
    height: 151px;
  }
`;

export default Wrapper;
