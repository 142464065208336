import styled from "styled-components";
import { palette, size } from "styled-theme";

export default styled.div`

  display: none;
  
  @media only screen and (min-width: ${size("XM")}) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width: 20%;
    background-color: ${palette("color", 47)};
    position: fixed;
    left: 0;
    top: 0;
    min-width: 300px;
  }
  
`;
